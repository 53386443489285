import { api } from './axios'
import { apiHelper } from '../utils/apiHelper'

export const getCommunicationPreferences = async (customerCode : number, id : string) => {
  try {
    const request = await apiHelper<any>(() => api.get(`/communication-preferences?customerCode=${customerCode}&id=${id}`))
    return request
    // return mockedData.communicationPreferencesResponse
  } catch (request) {
    return request
  }
}

export interface ISearchCommunicationPreferencesResponse {
  results: {
    customerCode: number,
    customerName: string,
    emailOrPhone: string
  }[]
}

export const searchCommunicationPreferences = async (emailOrPhone : string) => {
  try {
    const request = await apiHelper<ISearchCommunicationPreferencesResponse>(() => api.get(`/communication-preferences/search?emailOrPhone=${emailOrPhone}`))
    return request.data
  } catch (request) {
    return request
  }
}


export const setCommunicationPreferences = async (customerCode : number, id : string, communicationPreferences : any, removeSetTimestamp: boolean) => {
  await apiHelper<any>(() => api.post(`/communication-preferences`, {
    id, customerCode, communicationPreferences, removeSetTimestamp
  }))
}

export const getCommunicationPreferencesReport = async (customerId : number) => {
  const request = await apiHelper<any>(() => api.get(`/communication-preferences/report?customerId=${customerId}`))
  return request.data
}

export const toggleUserInterest = async (audienceListId : number, setInterest: boolean, id : string, customerCode : number) => {
  await apiHelper<any>(() => api.post(`/communication-preferences/interest`, {
    audienceListId, setInterest, id, customerCode
  }))
}